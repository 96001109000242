const URL = process.env.VUE_APP_URL_BASE_PUBLIC

const headers = {
   'Content-Type': 'application/json',
   'Accept': 'application/json',
   'x-functions-key': process.env.VUE_APP_CODE_BASE_PUBLIC
 }

export function otp ({ data }, cb, errorCb) {
    window.axios({
      method: 'post',
      url: URL+'otp',
      headers: headers,
      data: data
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function login (data,cb,errorCb){
    window.axios({
        method: 'post',
        url: URL+'login',
        headers: headers,
        data: data
      }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}